import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import Export from './Export';
import usePostApi from './services/usePostApi';
import Tabs from 'react-bootstrap/Tabs';
import Count from "./Count";
import { useOktaAuth } from '@okta/okta-react';
import {  AutocompleteSelect, MessageDialog } from "tgsccl-core-mui";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DataCount,CountyOption, StateOptionsData, CountyOptionsData } from "./IselectInput-interface";
import { AutoSelectOption } from 'tgsccl-core-mui/components/auto-complete-select/auto-complete-select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { AutocompleteChangeDetails, AutocompleteChangeReason, Chip, styled, Typography } from '@mui/material';
import { Button, UserButton } from "tgsccl-core-mui";
import {ui} from 'tgsccl-core';
const { Header, HeaderProject, HeaderTitle } = ui;
type UserInfoData = {
    name: string,
    email: string
  }
function Home() {
    const postAPI = usePostApi();
    const [countWell, setcountWell] = useState(0);
    const { oktaAuth,authState } = useOktaAuth();
    const [showLogin, setShowLogin] = useState(false);
    const [isDis, setisDis] = useState(false);
    const isLength = useRef(true);
    const [loaded, setLoaded] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const logout = async () => { await oktaAuth.signOut(); }
    const [userInfo, setUserInfo] = useState<UserInfoData | null>(null);
    const [state, setvalue] = useState({
        value: 0,
        statesToQuery:[''],
        statesFromSelectedCounties:[''],
        countiesToQUery:[''],
        conutyValue: 0,
        totalCountOfState: 0,
        totalCountOfCounty: 0,
        isCountylist: [],
        removeDup: [''],
        getsameCounty: []


    });
   
    const [count, setcount] = useState<DataCount>({
        wellcount: 0,
        getWellCount: 0,
        stateWellCount: 0,
        countyWellCount: 0,

        wellProductionCount: 0,
        stateProductionCount: 0,
        countyProductionCount: 0,
        getProductionCount: 0,

        wellActiveProducingCount: 0,
        statewellActiveProducingCount: 0,
        countywellActiveProducingCount: 0,
        getwellActiveProducingCount: 0,

        slantSideTrackCount: 0,
        stateslantSideTrackCount: 0,
        countyslantSideTrackCount: 0,
        getslantSideTrackCount: 0,

        slantDirectionalCount: 0,
        stateslantDirectionalCount: 0,
        countyslantDirectionalCount: 0,
        getslantDirectionalCount: 0,

        slantVerticalCount: 0,
        stateslantVerticalCount: 0,
        countyslantVerticalCount: 0,
        getslantVerticalCount: 0,

        slantHorizontalCount: 0,
        stateslantHorizontalCount: 0,
        countyslantHorizontalCount: 0,
        getslantHorizontalCount: 0,

        unkowncount: 0,
        stateUnknownCount: 0,
        countyUnknownCount: 0,
        getUnknownCount: 0,

        isselectedStaCountCount: 0,
        isSelectedCountyTotal: 0,
        isProductionCount: 0,
        selectedStateCount: 0,
        countget: 0,
        isSelectedSateHighlight:{fontWeight: 'normal'},
        isSelectedCountyHighlight:{fontWeight: 'normal'}
        // stateHighlightStyles: {
        //     option: (base:object, state: string) => ({
        //         ...base,
        //         backgroundColor: state ? "#ebf5ff" : "null",
        //         color: "#222",
        //         '&:hover': { backgroundColor: '#f5faff' }
        //     })
        // },
        // countyHighlightStyles: {
        //     option: (base:any, state: { isSelected: any; }) => ({
        //         ...base,
        //         backgroundColor: state.isSelected ? "#ebf5ff" : "null",
        //         color: "#222",
        //         '&:hover': { backgroundColor: '#f5faff' }
        //     })
        // }
    });

    const [isFileData, setisFileData] = React.useState("");

    const prevCountRef = useRef(count.countyWellCount);
    const prevCountProd = useRef(count.countyProductionCount);
    const prevCountActiveProd = useRef(count.countywellActiveProducingCount);
    const prevCountSlantside = useRef(count.countyslantSideTrackCount);
    const prevCountSlantDirect = useRef(count.countyslantDirectionalCount);
    const prevCountSlantVertical = useRef(count.countyslantVerticalCount);
    const prevCountSlantHorizontal = useRef(count.countyslantHorizontalCount);
    const [selectedStateOption, setSelectedStateOption] = useState<AutoSelectOption[]>([]);
    const [selectedCountyOption, setSelectedCountyOption] = useState<CountyOption[]>([]);
    const [allCountyOptions, setAllCountyOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState<StateOptionsData[]>([]);
    const [countyOptions, setCountyOptions] = useState<CountyOptionsData[]>([]);
    const addCommas = (num: number | string): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = (num: number | string): string => num.toString().replace(/[^0-9]/g, "");
    const sortIt = (sortBy: string) => (a:{[key:string]:number}, b:{[key:string]:number}) => {
        if (a[sortBy] > b[sortBy]) {
            return 1;
        } else if (a[sortBy] < b[sortBy]) {
            return -1;
        }
        return 0;
    }

    async function isListofStatesAndCounty() {
        setLoaded(true);
        let statebody = {

            "SearchType": "WpdProductCounts",
            "SearchFieldValues": [
                {
                    "Field": "WellActiveProducingCount",
                    "NotEqualOperator": false,
                    "Value": 0
                },
                {
                    "Field": "Country",
                    "EqualOperator": false,
                    "Value": "UNITED STATES OF AMERICA"
                }
            ],
            "Aggregations": [
                {
                    "AggregateBy": "State"
                }
            ],
            "From": 0,
            "Size": 0

        }
        let countyBody = {
            "SearchType": "WpdProductCounts",
            "SearchFieldValues": [
                {
                    "Query": "WellActiveProducingCount: >0",
                    "NotEqualOperator": false,
                    "Value": 0
                },
                {
                    "Field": "Country",
                    "EqualOperator": true,
                    "Value": "UNITED STATES OF AMERICA"
                }
            ],
            "IncludeFields": ["Country", "State", "StateCode", "StateCounty"],
            "From": 0,
            "Size": 5000
        }

        const getDataForState = await postAPI(statebody);
        const isListOfStatesresponse = getDataForState?.data.Aggregations;
        setLoaded(false);
        const totalCountOfState = isListOfStatesresponse.length;
        const sortedByState = isListOfStatesresponse.sort(sortIt('Key'));
        setStateOptions(sortedByState);
        let stateValue = [];
        for (let d = 0; d < isListOfStatesresponse.length; d++) {
            stateValue.push(isListOfStatesresponse[d].Key);
        }
        setLoaded(true);
        const getDateForCounty = await postAPI(countyBody);
        setLoaded(false);
        const isListOfCountyresponse = getDateForCounty?.data.Documents;

        const totalCountOfCounty = isListOfCountyresponse.length;
        const sortedByCounty = isListOfCountyresponse.sort(sortIt('StateCounty'));
        setCountyOptions(sortedByCounty);
        setAllCountyOptions(sortedByCounty);
        isOnLoadStateCount(stateValue);
        setvalue({ ...state, totalCountOfCounty, totalCountOfState });

    }
    const isSelectCounty = (e: React.SyntheticEvent<Element, Event>, value: unknown) => {
         const valueSelect = value as CountyOption[]
         setSelectedCountyOption(valueSelect);
        if (selectedStateOption.length == 0 && valueSelect.length == 0) {
            isListofStatesAndCounty();
        }
        if (valueSelect.length > selectedCountyOption.length) {
            if (valueSelect.length == 0 && selectedStateOption.length > 0) {
                isStateSelected(selectedStateOption, valueSelect)

            } else if (valueSelect.length > 0 && selectedStateOption.length == 0) {
                isCountySelected(valueSelect, selectedStateOption);
            } else if (valueSelect.length > 0 && selectedStateOption.length > 0) {

                isCountySelected(valueSelect, selectedStateOption);
            }
            else {
                isCountySelected(valueSelect, selectedStateOption);
            }

        } else {
            isCountyClear(valueSelect)
        }

    }
    const isSelectState = (e: React.SyntheticEvent<Element, Event>, value: unknown,  reason?: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        const valueSelect = value as AutoSelectOption[]
       setSelectedStateOption(valueSelect);
        if (reason == 'clear') {
            const clearedCounties = selectedCountyOption.filter((item) => !valueSelect.some((re:AutoSelectOption) => item.State == re.label));
            setSelectedCountyOption(clearedCounties);
            if (clearedCounties.length > 0) {
                isCountySelected(clearedCounties, valueSelect);
            } else {
                isListofStatesAndCounty();
            }
        } else {
            
            if (valueSelect.length == 0 && selectedCountyOption.length == 0) {
                isListofStatesAndCounty();
            }
            if (valueSelect.length > selectedStateOption.length) {
                
                const filteredCountiies = allCountyOptions.filter((item:CountyOption) => valueSelect.some((re:AutoSelectOption) => item.State == re.label));
               
                setCountyOptions(filteredCountiies);
                if (valueSelect.length > 0 && selectedCountyOption.length > 0) {
                    isCountySelected(selectedCountyOption, valueSelect);
                }
                else if (valueSelect.length > 0 && selectedCountyOption.length == 0) {
                    isStateSelected(valueSelect, selectedCountyOption);
                }
                else {
                    // isStateSelected(e, selectedCountyOption);
                }

            } else {
                // setSelectedStateOption(valueSelect);
                
                const filteredSelCountiies11 = allCountyOptions.filter((item:CountyOption) => valueSelect.some((re:AutoSelectOption) => item.State == re.label));
                setCountyOptions(filteredSelCountiies11);
                isStateClear(valueSelect);
            }
        }
    }
    async function isOnLoadStateCount(states:string[]) {
        setLoaded(true);
        let ipBody = {
            "SearchType": "WpdProductCounts",
            "SearchFieldValues": [
                {
                    "Field": "State",
                    "Values": states,
                    "NotEqualOperator": false
                }
            ],
            "Aggregations": [
                {
                    "AggregateBy": "State",
                    "PropertyName": "WellCount",
                    "AggregationTypes": [
                        "Sum"
                    ]
                }
            ],
            "From": 0,
            "Size": 10000
        }
        const isDataStateCount = await postAPI(ipBody);
        setLoaded(false);
        const isDataResponse = isDataStateCount?.data.Documents;
        let wellcount = 0;
        let wellProductionCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let unkowncount = 0;
        for (let i = 0; i < isDataResponse.length; i++) {
            wellcount += isDataResponse[i].WellCount;
            wellProductionCount += isDataResponse[i].WellProductionCount;
            wellActiveProducingCount += isDataResponse[i].WellActiveProducingCount;
            slantSideTrackCount += isDataResponse[i].SlantSideTrackCount;
            slantDirectionalCount += isDataResponse[i].SlantDirectionalCount;
            slantVerticalCount += isDataResponse[i].SlantVerticalCount;
            slantHorizontalCount += isDataResponse[i].SlantHorizontalCount;

        }
        unkowncount = wellcount - slantVerticalCount - slantSideTrackCount - slantDirectionalCount - slantHorizontalCount;
        setcount({ ...count, wellcount, wellProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, unkowncount })

    }

    function sessionKill() {
        setIsDialogOpen(true);
    }

    useEffect(() => {
        oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
            console.log('Token with key', key, ' has expired:');
            if (key == 'accessToken') {
                sessionKill();
            } else {
            }
        });
        //assign the ref's current value to the count Hook
        prevCountRef.current = count.countyWellCount;
        prevCountProd.current = count.countyProductionCount;
        prevCountActiveProd.current = count.countywellActiveProducingCount;
        prevCountSlantside.current = count.countyslantSideTrackCount;
        prevCountSlantDirect.current = count.countyslantDirectionalCount;
        prevCountSlantHorizontal.current = count.countyslantHorizontalCount;
        prevCountSlantVertical.current = count.countyslantVerticalCount;
    }, [count.countyWellCount, count.countyProductionCount, count.countywellActiveProducingCount, count.countyslantSideTrackCount,
    count.countyslantDirectionalCount, count.countyslantHorizontalCount, count.countyslantVerticalCount]);
    
    async function isStateSelected(paramstate:AutoSelectOption[], paramCounty:CountyOption[]) {
        let statesToQuery:string[] = [];
        let statesFromSelectedCounties:string[]=[];
        let countiesToQUery:string[] = [];
        let isSelectedSateHighlight = {};
        for (let county of paramCounty) {
            
            statesFromSelectedCounties.push(county["State"]);
            countiesToQUery.push(county["value"].substring(5));
        }
        statesFromSelectedCounties = [... new Set(statesFromSelectedCounties)];
        for (let j = 0; j < paramstate.length; j++) {
            statesToQuery.push(paramstate[j].label);
        }
        statesToQuery = statesToQuery.filter((el) => !statesFromSelectedCounties.includes(el));
        setLoaded(true);
        let ipBody = {
            "SearchType": "WpdProductCounts",
            "SearchFieldValues": [
                {
                    "Field": "State",
                    "Values": statesToQuery,
                    "NotEqualOperator": false
                }
            ],
            "Aggregations": [
                {
                    "AggregateBy": "State",
                    "PropertyName": "WellCount",
                    "AggregationTypes": [
                        "Sum"
                    ]
                }
            ],
            "From": 0,
            "Size": 10000
        }
        const responseData = await postAPI(ipBody);
        setLoaded(false);
        const isSelectedState = responseData?.data.Documents;
        const isSelectedCountyCount = responseData?.data.Aggregations;
        let isselectedStaCountCount = 0;
        let isSelectedCountyTotal = 0;
        let selectedStateCount = 0;
        let stateWellCount = 0;
        let stateProductionCount = 0;
        let statewellActiveProducingCount = 0;
        let stateslantSideTrackCount = 0;
        let stateslantDirectionalCount = 0;
        let stateslantHorizontalCount = 0;
        let stateslantVerticalCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let stateUnknownCount = 0;
        let getWellCount = 0;
        let getProductionCount = 0;
        let getwellActiveProducingCount = 0;
        let getslantSideTrackCount = 0;
        let getslantDirectionalCount = 0;
        let getslantHorizontalCount = 0;
        let getslantVerticalCount = 0;
        let getUnknownCount = 0;
        // setcombineCount(combineCount)
        let passQuery = "";
        for (let jx = 0; jx < statesToQuery.length; jx++) {
            
            for (let ix = 0; ix < isSelectedState.length; ix++) {
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].WellCount) {
                    stateWellCount += isSelectedState[ix].WellCount;
                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].WellProductionCount) {
                    stateProductionCount += isSelectedState[ix].WellProductionCount;

                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].WellActiveProducingCount) {
                    statewellActiveProducingCount += isSelectedState[ix].WellActiveProducingCount;
                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].SlantSideTrackCount) {
                    stateslantSideTrackCount += isSelectedState[ix].SlantSideTrackCount;
                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].SlantDirectionalCount) {
                    stateslantDirectionalCount += isSelectedState[ix].SlantDirectionalCount;

                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].SlantVerticalCount) {
                    stateslantVerticalCount += isSelectedState[ix].SlantVerticalCount;
                }
                if (isSelectedState[ix].State == statesToQuery[jx] && isSelectedState[ix].SlantHorizontalCount) {
                    stateslantHorizontalCount += isSelectedState[ix].SlantHorizontalCount;
                }

            }
        }
        for (let p = 0; p < isSelectedCountyCount.length; p++) {
            isselectedStaCountCount += isSelectedCountyCount[p].Value;
        }

        for (let oe = 0; oe < statesToQuery.length; oe++) {
            passQuery = statesToQuery[oe];

        }
        if (statesToQuery.length > 0 && paramCounty.length == 0) {

            isSelectedCountyTotal = isselectedStaCountCount;
            stateWellCount = stateWellCount;
            stateProductionCount = stateProductionCount;
            statewellActiveProducingCount = statewellActiveProducingCount;
            stateslantSideTrackCount = stateslantSideTrackCount;
            stateslantDirectionalCount = stateslantDirectionalCount;
            stateslantHorizontalCount = stateslantHorizontalCount;
            stateslantVerticalCount = stateslantVerticalCount;
        }
        else if (paramstate.length == 0 && paramCounty.length > 0) {
            isSelectedCountyTotal = countiesToQUery.length;
        }
        else {
            isSelectedCountyTotal = isselectedStaCountCount + countiesToQUery.length;
            getWellCount = stateWellCount + prevCountRef.current;
            getProductionCount = stateProductionCount + prevCountProd.current;
            getwellActiveProducingCount = statewellActiveProducingCount + prevCountActiveProd.current;
            getslantSideTrackCount = stateslantSideTrackCount + prevCountSlantside.current;
            getslantDirectionalCount = stateslantDirectionalCount + prevCountSlantDirect.current;
            getslantHorizontalCount = stateslantHorizontalCount + prevCountSlantHorizontal.current;
            getslantVerticalCount = stateslantVerticalCount + prevCountSlantVertical.current;
            getUnknownCount = getWellCount - getslantVerticalCount - getslantSideTrackCount - getslantDirectionalCount - getslantHorizontalCount;
        }
        selectedStateCount = statesToQuery.length + statesFromSelectedCounties.length;
        stateUnknownCount = stateWellCount - stateslantVerticalCount - stateslantSideTrackCount - stateslantDirectionalCount - stateslantHorizontalCount;
        setcount({ ...count, isSelectedSateHighlight, stateslantDirectionalCount, stateslantHorizontalCount, stateslantSideTrackCount, stateslantVerticalCount, selectedStateCount, getWellCount, statewellActiveProducingCount, getUnknownCount, getProductionCount, getwellActiveProducingCount, getslantSideTrackCount, getslantDirectionalCount, getslantHorizontalCount, getslantVerticalCount, isSelectedCountyTotal, isselectedStaCountCount, stateWellCount, stateProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, stateUnknownCount })

    }
    async function isCountySelected(getCounty:CountyOption[], paramstate:AutoSelectOption[]) {
        let statesToQuery:string[] = []
        let countiesToQUery:string[] = [];
        let statesFromSelectedCounties:string[] = [];
        let isSelectedSateHighlight={};
        let isSelectedCountyHighlight = {};
        for (let county of getCounty) {
            statesFromSelectedCounties.push(county["State"]);
            countiesToQUery.push(county["value"].substring(5));
        }
        statesFromSelectedCounties = [... new Set(statesFromSelectedCounties)];
        countiesToQUery = [... new Set(countiesToQUery)];
        for (let j = 0; j < paramstate.length; j++) {
            statesToQuery.push(paramstate[j].label);
        }
        statesToQuery = statesToQuery.filter((el) => !statesFromSelectedCounties.includes(el));
        setLoaded(true);
        let isCountylist = [];
        for (let h = 0; h < getCounty.length; h++) {
            isCountylist.push(getCounty[h].value);
        }
        let body = {};
        if (getCounty.length >= 1 && paramstate.length == 0) {
            body = {
                "SearchType": "WpdProductCounts",
                "SearchFieldValues": [
                    {
                        "Field": "StateCounty",
                        "NotEqualOperator": false,
                        "Values": isCountylist
                    }
                ],
                "Aggregations": [
                    {
                        "AggregateBy": "Country",
                        "OrderBy": "Value",
                        "Order": "asc",
                        "PropertyName": "WellCount",
                        "AggregationTypes": [
                            "Sum"
                        ]
                    },
                    {
                        "AggregateBy": "Country",
                        "OrderBy": "Value",
                        "Order": "asc",
                        "PropertyName": "WellActiveProducingCount",
                        "AggregationTypes": [
                            "Sum"
                        ]
                    }
                ],
                "From": 0,
                "Size": 5000
            }
        } else {
            body = {
                "SearchType": "WpdProductCounts",
                "SearchFieldValues": [
                    {
                        "Field": "County",
                        "NotEqualOperator": false,
                        "Value": null,
                        "Values": countiesToQUery
                    },
                    {
                        "Field": "State",
                        "Values": statesFromSelectedCounties,
                        "NotEqualOperator": false
                    }
                ],
                "Aggregations": [
                    {
                        "AggregateBy": "State",
                        "PropertyName": "WellCount",
                        "AggregationTypes": [
                            "Sum"
                        ]
                    }
                ],
                "From": 0,
                "Size": 10000
            }
        }
        const responseData = await postAPI(body);
        const selectedCountyData = responseData?.data.Documents;
        setLoaded(false);
        let countyWellCount = 0;
        let countyProductionCount = 0;
        let countywellActiveProducingCount = 0;
        let countyslantDirectionalCount = 0;
        let countyslantHorizontalCount = 0;
        let countyslantSideTrackCount = 0;
        let countyslantVerticalCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let countyUnknownCount = 0;
        let removeDup:string[] = [];
        let isselectedStaCountCount = 0;
        let getWellCount = 0;
        let getProductionCount = 0;
        let getwellActiveProducingCount = 0;
        let getslantSideTrackCount = 0;
        let getslantDirectionalCount = 0;
        let getslantHorizontalCount = 0;
        let getslantVerticalCount = 0;
        let getUnknownCount = 0;
        for (let zex = 0; zex < getCounty.length; zex++) {
            removeDup.push(getCounty[zex].State);
            removeDup = [... new Set(removeDup)];
        }
        for (let z = 0; z < isCountylist.length; z++) {
            for (let s = 0; s < selectedCountyData.length; s++) {
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyWellCount += selectedCountyData[s].WellCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyProductionCount += selectedCountyData[s].WellProductionCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countywellActiveProducingCount += selectedCountyData[s].WellActiveProducingCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyslantSideTrackCount += selectedCountyData[s].SlantSideTrackCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyslantDirectionalCount += selectedCountyData[s].SlantDirectionalCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyslantVerticalCount += selectedCountyData[s].SlantVerticalCount;
                }
                if (isCountylist[z] == selectedCountyData[s].StateCounty) {
                    countyslantHorizontalCount += selectedCountyData[s].SlantHorizontalCount;
                }
            }
            countyUnknownCount = countyWellCount - countyslantVerticalCount - countyslantSideTrackCount - countyslantDirectionalCount - countyslantHorizontalCount;
        }
        let passCountyQuery = "";
        if (countiesToQUery.length > 0 && statesToQuery.length > 0) {
            isStateSelected(paramstate, getCounty);
        } else if (statesFromSelectedCounties.length > 0) {
            count.selectedStateCount = statesFromSelectedCounties.length;
            count.isSelectedCountyTotal = countiesToQUery.length;
            getWellCount = countyWellCount;
            getProductionCount = countyProductionCount;
            getwellActiveProducingCount = countywellActiveProducingCount;
            getslantSideTrackCount = countyslantSideTrackCount;
            getslantDirectionalCount = countyslantDirectionalCount;
            getslantHorizontalCount = countyslantHorizontalCount;
            getslantVerticalCount = countyslantVerticalCount;
            getslantSideTrackCount = countyslantSideTrackCount;
            getUnknownCount = getWellCount - getslantVerticalCount - getslantSideTrackCount - getslantDirectionalCount - getslantHorizontalCount;
            
            for (let oe = 0; oe < countiesToQUery.length; oe++) {
                passCountyQuery = countiesToQUery[oe];
            }

        }
        setcount({
            ...count, isSelectedSateHighlight,isSelectedCountyHighlight,getUnknownCount, isselectedStaCountCount, getProductionCount, getwellActiveProducingCount, getslantSideTrackCount, getslantDirectionalCount, getslantHorizontalCount,
            getslantVerticalCount, countyWellCount, countywellActiveProducingCount, countyslantSideTrackCount, countyslantDirectionalCount, countyslantHorizontalCount, countyslantVerticalCount, getWellCount, countyProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, countyUnknownCount
        })
        setvalue({ ...state, removeDup });

    }

    function isClear() {
        let statesToQuery:string[] = [];
        let statesFromSelectedCounties:string[] = [];
        let countiesToQUery:string[] = [];
        setSelectedStateOption([]);
        setSelectedCountyOption([]);

        // setStateOptions(stateOptions);
        setCountyOptions(allCountyOptions);
        isListofStatesAndCounty();
        setvalue({ ...state, statesToQuery, statesFromSelectedCounties, countiesToQUery });
        setisDis(false);
        setError("");
        setisFileData("");
        setIsUwiSearchList([]);
        isLength.current = true;
        setcountWell(0)
    }
    const isCountyClear = (removedItem:CountyOption[]) => {
        setSelectedCountyOption(removedItem);

        if (removedItem.length == 0) {

            isStateSelected(selectedStateOption, removedItem);
        } else {
            isCountySelected(removedItem, selectedStateOption);
        }
        if (selectedStateOption.length == 0 && removedItem.length == 0) {
            isListofStatesAndCounty();
        }


    }


    const isStateClear = (removedItem:AutoSelectOption[]) => {
        const removeValue = selectedStateOption.filter(e => !removedItem.includes(e));
        const filteredSelCountiies11 = selectedCountyOption.filter(item => removedItem.some((ri:AutoSelectOption) => item.State == ri.key));

        if (removeValue.length > 0 && filteredSelCountiies11.length == 0 && selectedStateOption.length > 0) {
            isStateSelected(removedItem, selectedCountyOption);
        } else if (selectedCountyOption.length > 0 && selectedStateOption.length > 0) {
            isCountySelected(selectedCountyOption, removedItem)
        }
        if (removedItem.length === 0 && selectedStateOption.length <= 2) {
            const filteredSelCountiies1 = selectedCountyOption.filter(item => selectedStateOption.some((ri:AutoSelectOption) => item.State != ri.key));
            setSelectedCountyOption(filteredSelCountiies1);


        } else if (removedItem.length > 0 && selectedStateOption.length > 2) {
            const diff = selectedStateOption.filter(e => !removedItem.includes(e));
            const filteredSelCountiies2 = selectedCountyOption.filter(item => diff.some((e:AutoSelectOption) => item.State != e.key));
            setSelectedCountyOption(filteredSelCountiies2);
        }
        else {
            const remArr = selectedStateOption.filter(a => removedItem.some((r:AutoSelectOption) => a.label != r.label));
            const filteredSelCountiies3 = selectedCountyOption.filter(item => remArr.some((e:AutoSelectOption) => item.State != e.label));
            setSelectedCountyOption(filteredSelCountiies3);
        }
        if (removedItem.length === 0) {
            setCountyOptions(allCountyOptions);
        }

        if (removedItem.length == 0) {
            isListofStatesAndCounty();
        }

    }
    const onChangeFile = (e:ChangeEvent<HTMLInputElement>) => {
        var file = e.target .files?.[0];
        if(file){
        var reader = new FileReader();
        reader.onload = (e) => {
            let targetValue=e.target?.result;
            if(targetValue){ 
            const specialChar = /[\!\@\#\$\%\^\&\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\/\\\\]/g;
            var lengtharr = targetValue.toString().split(/[^a-zA-Z0-9\!\@\#\$\%\^\&\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\/\\\\]+/g).filter((v: any) => v).join('\n');
           
            var finalList = lengtharr!.split('\n');

            if (!specialChar.test(targetValue.toString())) {
                isLength.current = false;
                setisDis(false);
                setError("")
            }
            else {
                isLength.current = true;
                setisDis(true);
                setError("UWI’s must be between 10-16 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
            }

            finalList.map(valList => {
                if (valList.trim().length < 10 || valList.trim().length > 16 || /\s/.test(valList)) {

                    isLength.current = true;
                    setisDis(true);
                    setError("UWI’s must be between 10-16 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
                } else {
                    isLength.current = false;
                    // setisDis(false);
                }
            })
            finalList = [...new Set(finalList)];
            var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            finalList = finalList.filter((valListItem: string) => (valListItem.trim().length >= 10 && valListItem.trim().length <= 16 && !format.test(valListItem.trim())));
            setcountWell(finalList.length)
            setisFileData(lengtharr);
        
        };
    }
        reader.readAsText(file);
        }
    }
    const [error, setError] = useState("");

    const onChangeTextInFile = (e:ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value == '') {
            setcountWell(0);
            const file: HTMLInputElement | null = document.querySelector('#upload') as HTMLInputElement;
            console.log(file)
            file.value = '';
        }
        const re = /[\!\\\\/\\@\#\$\%\^\&\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
        let old_val = isFileData;
        var new_str = e.target.value.split('').map((str, i) => {
            if (old_val[i] !== str) {
                return { val: str, position: i }
            }
        }).filter(val => val !== undefined)[0];
        let newArr = (new_str && ((new_str.position == e.target.value.length - 1) && new_str.val == ',')) ? true : false;
        if (newArr) {
            let newArr1 = e.target.value.split('');
            newArr1.splice(new_str!.position, 1, '\n');
            let newV = newArr1.join('');
            e.target.value = newV;
            setisFileData(newV)
        }
        let str2 = e.target.value
        let str4 = str2.split('').map((row: string, i: number) => {
            if (str2[i - 1] == ' ' && row != ' ') return '\n' + row.trim()
            return row;
        })
        if (!re.test(e.target.value)) {
            isLength.current = false;
            setisDis(false);
            setError("")
        }
        else {

            isLength.current = true;
            setisDis(true);
            setError("UWI’s must be between 10-16 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
        }
        let str3 = str4.join('').split('\n');
        str3 = str3.filter((entry: string) => entry.trim() != '');

        str3.map((string:string) => {

            if (string.trim().length < 10 || string.trim().length > 16) {
                isLength.current = true;
                setisDis(true);
                setError("UWI’s must be between 10-16 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
            }
        })
        str3 = [...new Set(str3)];
        var format = /[ `\/!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        str3 = str3.filter((valListItems: string) => (valListItems.trim().length >= 10 && valListItems.trim().length <= 16 && !format.test(valListItems.trim())));
        setcountWell(str3.length)
        setisFileData(str4.join(''));

    }
    const [isUwiSearchList, setIsUwiSearchList] = useState<string[]>([]);
    function isUwiSearch() {
        const dataInFile= isFileData.split('\n');
        setIsUwiSearchList(dataInFile);
    }

    const [selectedTab, setSelectedTab] = useState('HeaderSearch');

    const handleSelect = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
        setisDis(false);
        isLength.current = true;
    }
    const StyledTab = styled(Tab)({
        textTransform: 'none',
        minWidth:"50%",
        "&.Mui-selected": {
          background:'#e6f4fd',
          border: '1px solid gray',
          borderRadius: '7px',
          color:"#17abab",
        },
      });
    const highlightState=(valueChange:AutoSelectOption)=>{
        var result:AutoSelectOption[] = selectedStateOption.filter(o1 => !selectedCountyOption.some(o2 => o1.key == o2.State));
        let returnValue=''
        if(result.length > 0){
            result.forEach((value) => {   
                if(value.key== valueChange.key)
                        {
                            returnValue= 'tgs-saga-font-bold'
                        }
            })
             }
        return returnValue;
    }
    const highlightCounty=()=>{
         if(selectedCountyOption.length>0){
            return 'tgs-saga-font-bold'
         }
         return ''
    }
    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                if( info.name && info.email){
                    setUserInfo({ name: info.name, email: info.email});
                }                
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [authState, oktaAuth]);
    useEffect(() => {
        isListofStatesAndCounty();
    }, []);
    useEffect(() => {
        isListofStatesAndCounty();
    }, []);
   
    return (
        <div className="page">
            <div className="content">
            <Header
                    logo={ <img className="max-"
                        src={`/assets/images/TGS_logo_white_XL.png`}
                        alt="logo" height="30" />}
                    project={<></> } menu={<></>} user={userInfo!}
                    logout={logout} UserButton={UserButton} SignInButton={Button}
                    />
                <div className="page d-flex">
                    <div className="col-md-1 searchCol" style={{ border: " 1px  solid #DCDCDC", height: '100vh' }}>
                        {loaded && <div id="cover-spin"></div>}
                        <div className="favIcons">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <button className="btn btn-info btnCls">
                                        <i className="fa fa-search" aria-hidden="true" style={{ color: 'white' }}></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="filterCol">
                        <div id="textbox">
                            <h5 className="alignleft" style={{ color: '#FFA500' }}>Production Data</h5>
                            <h5><a className="alignright clrSearch cursorPointCls" onClick={isClear}>Clear Search</a></h5>

                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <Box sx={{ typography: 'body1'}}>
                        <TabContext value={selectedTab}>
                            <TabList onChange={handleSelect} aria-label="lab API tabs example">
                                <StyledTab label="Header Search" value="HeaderSearch" />
                                <StyledTab label="UWI/API List" value="uwlList" />
                            </TabList>
       
                            <TabPanel value="HeaderSearch">
                            <h6 style={{ display: 'flex' }}>Search by State and County</h6>
                                                <AutocompleteSelect  limitTags={10} isMultiple={true}
                                                    options={stateOptions.map(e => ({ label: e.Key, key: e.Key }))}
                                                    variant="outlined"
                                                    value={selectedStateOption} placeHolder='Select State'
                                                    onSelect={isSelectState} isOptionsLoading={false} 
                                                    disableClearable={false} size="small"
                                                      onInputBoxTagLabelDivClass={highlightState}
                                                />
                                                    <br />
                                                   
                                                    <AutocompleteSelect limitTags={10} isMultiple={true}
                                                    options={countyOptions.map(e => ({ label: e.StateCounty, value: e.StateCounty, State: e.State, id: e.Id, key: e.StateCounty }))}
                                                    variant="outlined" value={selectedCountyOption} placeHolder='Select County'
                                                    isOptionsLoading={false} onSelect={isSelectCounty} label='Select County' 
                                                    disableClearable={false} size="small"
                                                    onInputBoxTagLabelDivClass={highlightCounty}
                                                   
                                                    />
                            </TabPanel>
                            <TabPanel value="uwlList">
                            <div className="tablebox">
                                <label><h6 style={{ fontSize: '14px' }}>UWI/API List <span style={{ color: '#ff0000' }}>&nbsp;*</span></h6> </label>
                                <textarea cols={35} rows={5} name="descr" value={isFileData} onChange={onChangeTextInFile} ></textarea><br />
                                <p className='uwiErr mb-4'>{error}</p>
                                <label htmlFor="upload" className="uploadFile"><h6 style={{ marginTop: '-5px' }}>Or&nbsp;<i className="fa fa-upload" aria-hidden="true" style={{ marginTop: '4px' }}></i>&nbsp;Upload UWI/API List</h6></label>
                                <input type="file" name="photo" accept=".txt" id="upload" onChange={onChangeFile} />

                            </div>
                            </TabPanel>
                        </TabContext>
                        </Box>
                       

                    </div>
                    <div className="countCards">
                        <div className="card-sec">
                            <div className="card">
                                <div className="card-header">
                                    <b className="pull-left" style={{ color: '#FFA500' }}>
                                        {selectedTab == 'HeaderSearch' ? (
                                            <span>Active Selection Counts</span>
                                        ) : (
                                            <span>Active Selection Counts</span>
                                        )}
                                    </b>

                                    {selectedTab == 'HeaderSearch' ? (
                                        <button className="btn btn-primary font-size22 pull-right" type="button" disabled={(selectedStateOption.length == 0 && selectedCountyOption.length == 0)} onClick={() => setShowLogin(true)}>Export</button>
                                    ) : (
                                        <button className="btn btn-primary font-size22 pull-right" type="button" disabled={isDis || isLength.current || isFileData.length == 0} onClick={() => { setShowLogin(true); isUwiSearch(); }}>Export</button>
                                    )}

                                    <Export show={showLogin} close={() => setShowLogin(false)} dataSelectedOption={selectedStateOption} dataSelectedCounty={selectedCountyOption}
                                        dataUWiList={isUwiSearchList} dataSelectedTab={selectedTab} />

                                </div>
                                {selectedTab == 'HeaderSearch' ? (<div>
                                    <h6 className="m-3" style={{ textAlign: 'left', display: 'block' }}>Region</h6>
                                    <div className="container-fluid px-6">
                                        <div className="row gx-4">
                                            <div className="col">
                                                <div className="p-3 border bg-light shadow">
                                                    <div className="">
                                                        <div className="text-center">
                                                            {(selectedStateOption.length > 0 &&
                                                                selectedCountyOption.length > 0) ? <h5 className="card-title">
                                                                {count.selectedStateCount}</h5> : (selectedStateOption.length > 0) ? <h5 className="card-title">
                                                                    {selectedStateOption.length}
                                                                </h5> : (selectedCountyOption.length > 0) ? <h5 className="card-title">
                                                                    {state.removeDup.length}
                                                                </h5> : <h5 className="card-title">
                                                                {state.totalCountOfState}
                                                            </h5>}
                                                            <p>State Count </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="p-3 border bg-light shadow">
                                                    <div className="">
                                                        <div className="text-center">
                                                            {(selectedStateOption.length > 0 && selectedCountyOption.length > 0) ?
                                                                <h5 className="card-title">{count.isSelectedCountyTotal}</h5>
                                                                : (selectedCountyOption.length > 0) ? <h5 className="card-title">
                                                                    {selectedCountyOption.length}
                                                                </h5> : (selectedStateOption.length > 0) ? <h5 className="card-title">
                                                                    {count.isselectedStaCountCount}
                                                                </h5> : <h5 className="card-title">
                                                                    {addCommas(addCommas(removeNonNumeric(state.totalCountOfCounty)))}
                                                                </h5>}

                                                        </div>
                                                        <p>County Count</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <Count data={count} data2={selectedStateOption} data3={selectedCountyOption} />
                                    </div>
                                </div>) : (
                                    <div className="container-fluid px-6">
                                        <div className='row gx-4'>
                                            <div className="">
                                                <div className="p-3 border bg-light shadow uwicardInside ">
                                                    <div className="text-center"><h5 className="card-title">{countWell.toLocaleString()}</h5><p>Well Count </p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div >
            </div>
            {isDialogOpen && <MessageDialog title={""}
                                         messageIcon={<InfoOutlinedIcon className="info-icon" sx={{ fontSize: 60 }} />}
                                         messageHeadText={'Your session has expired.'}
                                         messageText={'Click OK to return to the Login page.'} 
                                         openDialog={isDialogOpen} yesButtonText={"OK"} handleYesChange={() => { setIsDialogOpen(false); oktaAuth.signOut(); }} />}
        </div >
    );
}

export default Home;
