import React from "react";
import { CountyOption, DataCount } from "./IselectInput-interface";
import { AutoSelectOption } from "tgsccl-core-mui/components/auto-complete-select/auto-complete-select";

export const Count = (props:{data:DataCount,data2:AutoSelectOption[],data3:CountyOption[]}) => {
    return (
        <div>
            <h6 className="m-3" style={{ textAlign: 'left', display: 'block' }}>Well & Production</h6>

            <div className="container-fluid px-6">

                <div className="row gx-4">
                    <div className="col">
                        <div className="p-3 border bg-light shadow">
                            <div className="">
                                <div className="text-center">
                                    <div className="text-center">
                                        {(props.data2.length > 0 && props.data3.length > 0) ? <h5 className="card-title">
                                            {Number(props.data.getWellCount).toLocaleString('en-GB')}
                                        </h5>
                                            : (props.data3.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.countyWellCount).toLocaleString('en-GB')}
                                            </h5> : (props.data2.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.stateWellCount).toLocaleString('en-GB')}
                                            </h5> : <h5 className="card-title">
                                                {Number(props.data.wellcount).toLocaleString('en-GB')}
                                            </h5>}
                                        <p>Well Count</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="p-3 border bg-light shadow">
                            <div className="">
                                <div className="text-center">
                                    <div className="text-center">
                                        {(props.data2.length > 0 && props.data3.length > 0) ? <h5 className="card-title">
                                            {Number(props.data.getProductionCount).toLocaleString('en-GB')}
                                        </h5>
                                            : (props.data3.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.countyProductionCount).toLocaleString('en-GB')}
                                            </h5> : (props.data2.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.stateProductionCount).toLocaleString('en-GB')}
                                            </h5> : <h5 className="card-title">
                                                {Number(props.data.wellProductionCount).toLocaleString('en-GB')}
                                            </h5>}
                                        <p>Well Production</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="p-3 border bg-light shadow">
                            <div className="">
                                <div className="text-center">
                                    <div className="text-center">
                                        {(props.data2.length > 0 && props.data3.length > 0) ? <h5 className="card-title">
                                            {Number(props.data.getwellActiveProducingCount).toLocaleString('en-GB')}
                                        </h5>
                                            : (props.data3.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.countywellActiveProducingCount).toLocaleString('en-GB')}
                                            </h5> : (props.data2.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.statewellActiveProducingCount).toLocaleString('en-GB')}
                                            </h5> : <h5 className="card-title">
                                                {Number(props.data.wellActiveProducingCount).toLocaleString('en-GB')}
                                            </h5>}
                                        <p>Actively Producing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h6 className="m-3" style={{ textAlign: 'left', display: 'block' }}>Profile</h6>
            <div className="container-fluid px-6">
                <div className="px-6">
                    <div className="row gx-4">
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                <div className="">
                                    <div className="text-center">
                                        {(props.data2.length > 0 && props.data3.length > 0) ? <h5 className="card-title">
                                            {Number(props.data.getUnknownCount).toLocaleString('en-GB')}
                                        </h5>
                                            : (props.data3.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.countyUnknownCount).toLocaleString('en-GB')}
                                            </h5> : (props.data2.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.stateUnknownCount).toLocaleString('en-GB')}
                                            </h5> : <h5 className="card-title">
                                                {Number(props.data.unkowncount).toLocaleString('en-GB')}
                                            </h5>}
                                        <p>Unknown</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                <div className="">
                                    <div className="text-center">
                                        {(props.data2.length > 0 && props.data3.length > 0) ? <h5 className="card-title">
                                            {Number(props.data.getslantSideTrackCount).toLocaleString('en-GB')}
                                        </h5>
                                            : (props.data3.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.countyslantSideTrackCount).toLocaleString('en-GB')}
                                            </h5> : (props.data2.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.stateslantSideTrackCount).toLocaleString('en-GB')}
                                            </h5> : <h5 className="card-title">
                                                {Number(props.data.slantSideTrackCount).toLocaleString('en-GB')}
                                            </h5>}
                                        <p>Sidetrack</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                <div className="">
                                    <div className="text-center">
                                        {(props.data2.length > 0 && props.data3.length > 0) ? <h5 className="card-title">
                                            {Number(props.data.getslantDirectionalCount).toLocaleString('en-GB')}
                                        </h5>
                                            : (props.data3.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.countyslantDirectionalCount).toLocaleString('en-GB')}
                                            </h5> : (props.data2.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.stateslantDirectionalCount).toLocaleString('en-GB')}
                                            </h5> : <h5 className="card-title">
                                                {Number(props.data.slantDirectionalCount).toLocaleString('en-GB')}
                                            </h5>}
                                        <p>Directional</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                <div className="">
                                    <div className="text-center">
                                        {(props.data2.length > 0 && props.data3.length > 0) ? <h5 className="card-title">
                                            {Number(props.data.getslantVerticalCount).toLocaleString('en-GB')}
                                        </h5>
                                            : (props.data3.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.countyslantVerticalCount).toLocaleString('en-GB')}
                                            </h5> : (props.data2.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.stateslantVerticalCount).toLocaleString('en-GB')}
                                            </h5> : <h5 className="card-title">
                                                {Number(props.data.slantVerticalCount).toLocaleString('en-GB')}
                                            </h5>}
                                        <p>Vertical</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 border bg-light shadow">
                                <div className="">
                                    <div className="text-center">
                                        {(props.data2.length > 0 && props.data3.length > 0) ? <h5 className="card-title">
                                            {Number(props.data.getslantHorizontalCount).toLocaleString('en-GB')}
                                        </h5>
                                            : (props.data3.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.countyslantHorizontalCount).toLocaleString('en-GB')}
                                            </h5> : (props.data2.length > 0) ? <h5 className="card-title">
                                                {Number(props.data.stateslantHorizontalCount).toLocaleString('en-GB')}
                                            </h5> : <h5 className="card-title">
                                                {Number(props.data.slantHorizontalCount).toLocaleString('en-GB')}
                                            </h5>}

                                        <p>Horizontal</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Count